import React, { useContext, Fragment, useState, useEffect, useRef } from "react";
import { AppContext } from "../../helpers/AppContext";
import makeRequest from "../../helpers/DataRequester";
import URL_ROUTES, { generateUrl } from "../../constants";
import { useLoading } from "../../helpers/LoadingContext";
import loadingGif from "../../assets/Loading.png";

export default function LocationDetails({ setDetails, setEdit, setLocations, setAdd, setCsvContainer }) {
    const { chosenLocationId, LocationDetails, chosenLocationName } = useContext(AppContext);
    const [infoMessage, setInfoMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [logoLoading, setLogoLoading] = useState(false);
    const fileInputRef = useRef(null);
    const { setApiCallInProgress } = useLoading();

    const [qrCode, setQrCode] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const toggleComponent = () => {
        setEdit(true);
        setAdd(false);
        setDetails(false);
        setLocations(false);
        setCsvContainer(false)
    };

    // const toggleLocationStatus = async () => {
    //     try {
    //         const response = await makeRequest(URL_ROUTES.TOGGLE_LOCATION_STATUS, "POST", {}, { chosenLocationId }, false);
    //         if (response.status === 200) {
    //             console.log("Location toggled");
    //             setDetails(false);
    //             setTimeout(function () {
    //                 window.location.reload();
    //             }, 500);
    //         } else {
    //             console.log(response.message);
    //         }
    //     } catch (error) {
    //         console.error("Error toggling status of location:", error);
    //     }
    // };

    const handleDelete = async () => {
        try {
            const response = await makeRequest(URL_ROUTES.DELETE_LOCATION, "DELETE", {}, { chosenLocationId }, false);
            if (response.status === 200) {
                console.log("Location deleted");
                setDetails(false);
                setTimeout(function () {
                    window.location.reload();
                }, 500);
            } else {
                console.log(response.message);
            }
        } catch (error) {
            console.error("Error deleting location:", error);
        }
    };

    /**
     * Generates a QR code for the chosen location
     * @returns {Promise<void>}
     */
    async function generateQRCode() {
        try {
            setApiCallInProgress(true);
            const response = await makeRequest(URL_ROUTES.GENERATE_QR, "POST", {}, { chosenLocationId });
            setQrCode(response);
            console.log("QR code generated", response);
        } catch (e) {
            console.error("Error generating QR code:", e);
        }
        setApiCallInProgress(false);
    }

    const handleImageChange = async (e) => {
        setLogoLoading(true);
        const selectedImage = e.target.files[0];
        setErrorMessage("")

        if (chosenLocationName && selectedImage) {
            try {
                const formData = new FormData();
                formData.append("image", selectedImage);
                let url = generateUrl('UPDATE_IMAGE', { name: chosenLocationName, locationId: chosenLocationId });
                // upload image
                const response = await makeRequest(url, 'POST', {}, formData, false, true);
                // Update the state with the new image URL
                setImageUrl(response.imageUrl);
                setInfoMessage(response.message);
            } catch (e) {
                console.error("Error uploading image:", e.message);
                setErrorMessage(e.message);
            }
            finally {
                setLogoLoading(false);
            }
        }
        else {
            setErrorMessage("Please fill in the location name or image first")
        }
    };

    async function uploadImage(e) {
        e.preventDefault();
        fileInputRef.current.click();
    };

    useEffect(() => {
        setQrCode('');
    }, [chosenLocationId]);

    return (
        <Fragment>
            {logoLoading && <img className="loading" src={loadingGif} alt="Loading is here" />}

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {infoMessage && <p className="info-message">{infoMessage}</p>}
            <br />
            <div className="details-action-container">
                <div className="location-details-container">
                    <div>
                        <div className="location-detail">
                            <p className="location-title">Manager Full Name:</p>
                            <p>{LocationDetails.admin}</p>
                        </div>
                        <div className="location-detail">
                            <p className="location-title">Location Address:</p>
                            <p>{LocationDetails.address}</p>
                        </div>
                        <div className="location-detail">
                            <p className="location-title">Location Name:</p>
                            <p>{LocationDetails.name}</p>
                        </div>
                        {/* <div className="location-detail">
                            <p className="location-title">Login email:</p>
                            <p>{LocationDetails.email}</p>
                        </div>
                        <div className="location-detail">
                            <p className="location-title">Location status:</p>
                            {LocationDetails.status ? <p>Active</p> : <p>Deactive</p>}
                        </div> */}
                        <div className="location-detail">
                            <p className="location-title">Application link:</p>
                            <p>{process.env.REACT_APP_URL}/{LocationDetails.backUpUrl}/pass</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="details-btn-container">
                <div>
                    <button className="edit-btn" onClick={() => toggleComponent()}>
                        Edit
                    </button>
                    <button
                        className="delete-btn"
                        onClick={() => {
                            if (window.confirm("Are you sure to delete this location?"))
                                handleDelete();
                        }}
                    >
                        Delete
                    </button>
                </div>
                <div className='portal-right-side-button '>
                    <button
                        className="portal-btn-generateQR"
                        onClick={generateQRCode}
                    >
                        {qrCode ? 'Re-generate QR' : 'Generate QR'}
                    </button>
                    {/* image upload */}
                    <div>
                        <input
                            ref={fileInputRef}
                            type="file"
                            name="image"
                            id="image"
                            onChange={handleImageChange}
                            required
                            style={{ display: "none" }}
                        />
                        <button
                            onClick={uploadImage}
                            className="portal-btn-generateQR"
                        >
                            Upload logo
                        </button>
                    </div>
                </div>

                {/*{LocationDetails.status ? (*/}
                {/*    <button*/}
                {/*        className="deactive-btn"*/}
                {/*        onClick={() => {*/}
                {/*            if (window.confirm("Are you sure to deactivate this location?"))*/}
                {/*                toggleLocationStatus();*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Deactivate*/}
                {/*    </button>*/}
                {/*) : (*/}
                {/*    <button*/}
                {/*        className="active-btn"*/}
                {/*        onClick={() => {*/}
                {/*            if (window.confirm("Are you sure to activate this location?"))*/}
                {/*                toggleLocationStatus();*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Activate*/}
                {/*    </button>*/}
                {/*)}*/}
            </div>
            {/* div to display image */}
            <div className="details-action-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
                <div className="image-holder" style={{ height: "200px" }}>
                    {LocationDetails?.logo && <img src={LocationDetails.logo} alt="location" style={{ height: "200px" }} />}
                    {
                        /**
                         * display qr code from base64 string
                         */
                        qrCode && <img src={qrCode} alt="QR" style={{ height: "200px" }} />
                    }
                </div>
            </div>
        </Fragment>
    );
}
