/**
 * Get message from sessionStorage
 * @param type
 * @returns {*}
 */
const getMessage = (type = 'message') => {
    const sessionData = JSON.parse(sessionStorage.getItem('message'));
    //in message find the one which has type: welcome
    const savedMessage = sessionData?.find((item) => item.type === type);

    return savedMessage?.message;
}

export { getMessage };