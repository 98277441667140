import React, { useState, useContext, useEffect, Fragment } from "react";
import { AppContext } from "../../helpers/AppContext";
import { CSVLink } from "react-csv";
import moment from "moment";
import loadingGif from "../../assets/Loading.png";
import makeRequest from "../../helpers/DataRequester";
import URL_ROUTES from "../../constants";

export default function Residents() {
    const [residents, setResidents] = useState([]);
    const [CSVdata, setCSVdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const { chosenLocationId, chosenLocationName, inputsTable } = useContext(AppContext);

    useEffect(() => {
        if (chosenLocationId !== "") {
            getData();
        }
    }, [chosenLocationId]);

    const getData = async () => {
        setLoading(true);
        if (chosenLocationId !== "") {
            try {
                let url = `${URL_ROUTES.GET_RESIDENTS_DATA}?chosenLocationId=${chosenLocationId}`;
                const response = await makeRequest(url, "GET")

                if (response) {
                    let residents = response.map((resident) => ({
                        name: resident.name,
                        unit: resident.unit,
                        mobile: resident.mobile,
                        email: resident.email,
                        resident: resident.resident,
                        companyName: resident.companyName,
                        purposeOfVisit: resident.purposeOfVisit,
                        visitDuration: resident.visitDuration,
                        time: moment(resident.time).format("lll"),
                        answer: resident.answer,
                    }));

                    setResidents(residents);
                    setCSVdata(residents);
                }
            }
            catch (error) {
                console.error("Error getting message:", error);
                setWarningMessage(error.message);
            }
            finally {
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            <div className="components-container">
                <div className="csv-container csv-container-residents">
                    <div className="residents-header">
                        <h2 className="questions-title">Review Residents</h2>
                        <p className="location-name">{chosenLocationName}</p>
                    </div>
                    {loading ? (
                        <img className="loading" src={loadingGif} alt="Loading is here" />
                    ) : null}

                    <div>
                        <CSVLink className="portal-btn-csv" data={CSVdata}>
                            Export CSV
                        </CSVLink>
                    </div>
                </div>
            </div>
            <div className="resident-container-div">
                <table className="resident-container">
                    <thead>
                    <tr className="resident-title">
                        {inputsTable?.name && <th>Name</th>}
                        {inputsTable?.unit && <th>Apt No.</th>}
                        {inputsTable?.mobile && <th>Mobile</th>}
                        {inputsTable?.email && <th>Email</th>}
                        {inputsTable?.resident && <th>Resident</th>}
                        {inputsTable?.companyName && <th>Company Name</th>}
                        {inputsTable?.purposeOfVisit && <th>Purpose of Visit</th>}
                        {inputsTable?.visitDuration && <th>Visit Duration</th>}
                        <th>Timestamp</th>
                        <th>Answer</th>
                    </tr>
                    </thead>
                    <tbody>
                    {residents.map((resident, index) => (
                        <tr key={index} className="resident-data">
                            {inputsTable?.name && (
                                <td className="data-name">{resident.name}</td>
                            )}
                            {inputsTable?.unit && <td>{resident.unit}</td>}
                            {inputsTable?.mobile && <td>{resident.mobile}</td>}
                            {inputsTable?.email && <td>{resident.email}</td>}
                            {inputsTable?.resident && <td>{resident.resident}</td>}
                            {inputsTable?.companyName && <td>{resident.companyName}</td>}
                            {inputsTable?.purposeOfVisit && <td>{resident.purposeOfVisit}</td>}
                            {inputsTable?.visitDuration && <td>{resident.visitDuration}</td>}
                            <td className="data-time">{resident.time}</td>
                            <td>{resident?.answer || " "}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
}
