import React, { useEffect, useState, useContext, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../helpers/AppContext";
import loadingGif from "../assets/Loading.png";
import makeRequest from "../helpers/DataRequester";
import URL_ROUTES from "../constants";
import "../styles/components/pass.scss";
import { getMessage } from "../helpers/helpers";
import logo from '../assets/OrangePass-Logo.png'

export default function Pass() {
    const warningLogo = "data: image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAzCAYAAADVY1sUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKNSURBVHgB3ZrhcdswDIVfev1fbVBuEG9gdYJmA3uDdgOlE3QEeQN3A7kTxJ5A7AT2BqkQUxfakUUQBGUn3x1Oio8C8ESIpKjcQZ+is7Kzr+44c79vnf11xwMUuUMaQ0kb5rUWiuJihKQkzcVCKO6SEEqaEr13xxL6SXOxOAradLbDBXEk5JaS5kJiLDxxJOQZH4BP+CB8Rj6ojvsSIAyOpVsgEy2O5aVl+86qkYSXGWK22kLIl0EYkyEunjKJKDtbu99bdz47E7NXit3ABdBwtvCSfBxp9+i1qzSF1AqO9l5yD4z2pWtbQKdXahp+LdLZeOc/GO0rd6SR7Q / SsSTkH9LZeuczRnu / jUU6VqtHfAqlNjG8CNni / bOjmf3gTHqX6Nqd9 / cKcdC1FvKFap//C9IheA09fgtzaDScGOhRCHOofCel0Ik2khzmvgPp3dBGksObZ7sROOFQ4nUmDxEbvxlyUgkchfAXpHQeGhlj4w+uIozA0RhLbmDIhZj+Qv9V1+J0zZTKl4HfNGd0/+3zDRX0esTgdGW7R3i4jom9GHMUO3qF8Jf03xntRWVFnO+i0FS/gR6HC+eprHBWVkPbQb9w+6y4DRvolBaV6twZ50HnxHxCBCXTqTbJD/kQnF7RRrU3ekqGY815wTDi3UNIHXCs9T5SIPxOVI85CH3ooQAtxu+8Rfp7f2hP2Hb2DYlxfiJuosphCyixAvB8JauhSF9iU4tokeEzhIHepjPHOItMMZx9XS17QGamePg5+8cqVEA2ERUmJoeYyXrinCV0BgDyscCVMUgbmulazqeISTCQ7R83yPi5OgUa0TilRm2u9jxwMRhfNa9x+//rcgLVPZWOX0ZzvGNIUHYB/wFuyIDNPWOITAAAAABJRU5ErkJggg==";
    const { location } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [clientLogo, setClientLogo] = useState(logo);
    const [qr, setQr] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { returnUser, setReturnUser, returnUserData, setReturnUserData } = useContext(AppContext);

    useEffect(() => {
        setLoading(true);
        setIsButtonDisabled(true);

        makeRequest(URL_ROUTES.GET_PASS, 'POST', {}, { location }, true)
            .then((response) => {
                if (response.status === 200) {
                    const { logo, id, qr } = response;
                    if (logo) setClientLogo(logo);
                    setQr(qr);
                    fetchData(id);

                    if (localStorage.getItem("returnUserData") !== null) {
                        setReturnUserData(JSON.parse(localStorage.getItem("returnUserData")));
                        setReturnUser(true);
                    }
                } else {
                    navigate('/404');
                }
            })
            .catch((error) => {
                console.log('error ', error);
            });
    }, []);

    const getUserData = (id, uid) => {
        makeRequest(URL_ROUTES.GET_PASS_USER_DATA, 'POST', {}, { id, uid }, true)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response); //
                    const { data } = response;
                    setReturnUserData(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchData = (id) => {
        makeRequest(URL_ROUTES.FETCH_PASS, 'POST', {}, { locationId: id }, true)
            .then((response) => {
                const { rules, questions, message, warning, inputs } = response;

                // Set data in sessionStorage
                sessionStorage.setItem("rules", JSON.stringify(rules));
                sessionStorage.setItem("questions", JSON.stringify(questions));
                sessionStorage.setItem("message", JSON.stringify(message));
                sessionStorage.setItem("warning", JSON.stringify(warning));
                sessionStorage.setItem("inputs", JSON.stringify(inputs));

                setLoading(false);
                setIsButtonDisabled(false);
                setWelcomeMessage(getMessage('message'));
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false);
                setIsButtonDisabled(false);
            });
    };

    const navigatePage = () => {
        if (
            JSON.parse(sessionStorage.getItem("rules")).length > 0 &&
            JSON.parse(sessionStorage.getItem("rules"))[0].content !== ""
        ) {
            navigate(`/${location}/rules`);
        } else if (
            JSON.parse(sessionStorage.getItem("questions")).length > 0 &&
            JSON.parse(sessionStorage.getItem("questions"))[0].content !== ""
        ) {
            navigate(`/${location}/questions`);
        } else {
            navigate(`/${location}/submit`);
            sessionStorage.setItem("answer", "Not required");
        }
    };



    return (
        <Fragment>
            <div className="main-container">
                <div className="qr-container">
                    {loading ? (
                        <img className="loading" src={loadingGif} alt="Loading is here" />
                    ) : null}

                    {returnUser ? (
                        <div>
                            <h3>Welcome back</h3>
                            <span>{returnUserData.name}</span>
                        </div>
                    ) : null}
                    <div className="entry-option-main">
                        <div className="entry-option">
                            <div className="entry-option-welcome">
                                {welcomeMessage ? (<h2>{welcomeMessage}</h2>) : (<><span>Enter</span> <h2>to continue with the pass</h2></>)}
                            </div>
                            <div className="logo-container">
                                <img className="client-logo" src={clientLogo} alt="" />
                                <button
                                    disabled={isButtonDisabled}
                                    onClick={() => navigatePage()}
                                    style={{ marginBottom: '20px' }}
                                >
                                    Start
                                </button>
                            </div>
                        </div>
                        <div className="entry-option">
                            <div className='entry-header-title'>
                                QR Scan
                            </div>
                            <div className="entry-header-desc">
                                to use your own device
                            </div>
                            <div className="qrcode-container">

                            </div>
                            <div className="logo-container">
                                <img className="client-logo client-qr" src={qr} alt="Client QR" />
                            </div>
                        </div>
                    </div>
                    <div class="entry-option-footer">
                        <div class="lockicon">
                            <img src={warningLogo} alt="Lock is here" />
                        </div>
                        <div class="footer-description">
                            Data submitted will not be used for any other purpose and is kept secure using industry leading technology
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
