import React, { useEffect, useState, useContext, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/components/submit.scss";
import { AppContext } from "../helpers/AppContext";
import Bar from "./Bar";

export default function Submit() {
  const { setProgressBar, returnUser, setReturnUser, setReturnUserData, returnUserData } = useContext(AppContext);
  const navigate = useNavigate();
  const { location } = useParams();

  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const [form, setForm] = useState(true);
  const [showAdditionalField, setShowAdditionalField] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    unit: "",
    email: "",
    mobile: "",
    companyName: "",
    guestType: "default",
    purposeOfVisit: "default",
    additionalField: "",
    visitDuration: "",
    agreed: false,
  });

  const purposeOptions = [
    { value: "default", text: "Please select purpose for your visit" },
    { value: "Maintenance contractor", text: "Maintenance contractor" },
    { value: "Product delivery", text: "Product delivery - supplier" },
    { value: "A schedule meeting", text: "A schedule meeting" },
    { value: "Other", text: "Other" },
  ];

  useEffect(() => {
    setProgressBar(100);
    const storedInputs = JSON.parse(sessionStorage.getItem("inputs"))[0];
    setInputs(storedInputs);

    if (JSON.parse(localStorage.getItem("returnUserData"))) {
      const storedReturnUserData = JSON.parse(localStorage.getItem("returnUserData"));
      setReturnUserData(storedReturnUserData);
      setReturnUser(true);
    }

    if (returnUser) {
      setLoading(true);
      setForm(false);
      setFormData(prevState => ({
        ...prevState,
        ...returnUserData,
      }));
    }
    setLoading(false);
  }, [returnUser]);

  useEffect(() => {
    checkPhoneNumber();
  }, [formData.mobile, formData.agreed]);

  function checkPhoneNumber() {
    const phoneRegex = /^\d{8,}$/;

    if (inputs.mobile && !phoneRegex.test(formData.mobile)) {
      setWarningMessage("Please enter a valid phone number");
      setIsButtonDisabled(true);
    } else if (!formData.agreed) {
      setWarningMessage("Please agree to the terms and conditions");
      setIsButtonDisabled(true);
    } else {
      setWarningMessage("");
      setIsButtonDisabled(false);
    }
  }

  function handleInputChange(event) {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData(prevData => ({
      ...prevData,
      [name]: newValue,
    }));

    // Check if the selected option requires an additional field
    if (name === "purposeOfVisit") {
      setShowAdditionalField(value === "Other" || value === "A schedule meeting");
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    localStorage.setItem('returnUserData', JSON.stringify(formData));
    navigate(`/${location}/userWaiver`);
  };

  // Handle navigation back
  function backHandle() {
    navigate(-1);
  }

  return (
    <Fragment>
      <Bar />
      <div className="main-container">

        <div className="back-button-container">
          <div className="back-button" onClick={backHandle}>
            Back
          </div>
        </div>

        {(returnUser && form === false) && (
          <div className="return-container">
            <div className="return-box">
              <h2>{returnUserData.name},</h2>
              <h3>You have already given us your contact information.</h3>
            </div>
            <button
              style={{
                border: 'none',
                background: 'none',
                padding: 0,
                color: '#ff7c53',
                textDecoration: 'underline',
                cursor: 'pointer',
                boxShadow: 'none',
                borderRadius: 0,
                marginTop: 'unset',

              }}
              onClick={() => setForm(true)}
            >
              Edit information
            </button>

          </div>
        )}

        <div style={{ height: "calc(100% - 60px)" }}>
          <form className="form" name="submit" onSubmit={handleSubmit}>
            {(!returnUser || form) && (
              <>
                <div className="information-form-title">
                  Please provide your information to get your pass
                </div>

                {inputs.name && (
                  <div className="input-wrapper">
                    <input
                      placeholder="Full name"
                      type="text"
                      id="name"
                      name="name"
                      autoComplete="off"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}

                {inputs.unit && (
                  <div className="input-wrapper">
                    <input
                      placeholder="Unit No"
                      type="text"
                      id="unit"
                      name="unit"
                      autoComplete="off"
                      value={formData.unit}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}

                {inputs.email && (
                  <div className="input-wrapper">
                    <input
                      placeholder="Email"
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="off"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}

                {inputs.mobile && (
                  <div className="input-wrapper">
                    <input
                      className="phone-number"
                      placeholder="Phone No"
                      type="tel"
                      id="mobile"
                      name="mobile"
                      autoComplete="off"
                      value={formData.mobile}
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                {inputs.resident && (
                  <div className="input-wrapper">
                    <select
                      required
                      className="select-guest"
                      name="guestType"
                      value={formData.guestType}
                      onChange={handleInputChange}
                    >
                      <option value="default" disabled>
                        Please select (Resident/guest)
                      </option>
                      <option value="Resident">Resident</option>
                      <option value="Guest">Guest</option>
                    </select>
                  </div>
                )}

                {inputs.companyName && (
                  <div className="input-wrapper">
                    <input
                      placeholder="Company Name"
                      type="text"
                      id="companyName"
                      name="companyName"
                      autoComplete="off"
                      value={formData.companyName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}

                {inputs.purposeOfVisit && (
                  <div className="input-wrapper">
                    <select
                      required
                      className="select-guest select-width"
                      name="purposeOfVisit"
                      value={formData.purposeOfVisit}
                      onChange={handleInputChange}
                    >
                      {purposeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Render additional input field if the selected option is either "Other" or "A schedule meeting" */}
                {showAdditionalField && (
                  <div className="input-wrapper">
                    <input
                      placeholder={
                        formData.purposeOfVisit === "Other"
                          ? "Please specify the purpose of your meeting"
                          : "Please specify the name of the person you are meeting"
                      }
                      type="text"
                      id="additionalField"
                      name="additionalField"
                      autoComplete="off"
                      value={formData.additionalField}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}

                {inputs.visitDuration && (
                  <div className="input-wrapper">
                    <input
                      placeholder="How long will you be visit the hotel for"
                      type="text"
                      id="visitDuration"
                      name="visitDuration"
                      autoComplete="off"
                      value={formData.visitDuration}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}

                <p className="warning-message">{warningMessage}</p>
                <p className="error-message">{errorMessage}</p>

                <div>
                  <input
                    type="checkbox"
                    id="agreed"
                    name="agreed"
                    checked={formData.agreed}
                    onChange={handleInputChange}
                    required
                    style={{ marginTop: "10px" }}
                  />
                  <label htmlFor="agreed" className="agreedLabelText">
                    &nbsp; I hereby certify that the above statements are true and
                    correct to the best of my knowledge. I understand that a false
                    statement may disqualify me for benefits and lead to penalties
                  </label>
                </div>
              </>
            )}
            <button
              disabled={isButtonDisabled}
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>

        {/* home link here */}
        <a
          className="website-link"
          href="https://www.orangesafepass.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "flex", justifyContent: "center" }}
        >
          www.orangesafepass.com
        </a>
      </div>
    </Fragment>
  );
}
