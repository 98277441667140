import loadingGif from "../assets/Loading.png";
import "../styles/components/loader.scss";
import React from 'react';

const LoadingModal = ({ show }) => {
    if (!show) return null;

    return (
        <div className="loading-modal">
            <div className="loading-spinner"></div>
            <img src={loadingGif} alt="Loading..." />
        </div>
    );
};

export default LoadingModal;
