import React, { useState, useEffect, useContext, Fragment } from "react";
import { AppContext } from "../../helpers/AppContext";
import AddLocation from "./AddLocation";
import EditLocation from "./EditLocation";
import LocationDetails from "./LocationDetails";
import makeRequest from "../../helpers/DataRequester";
import URL_ROUTES from "../../constants";
import { useLoading } from "../../helpers/LoadingContext";

export default function Locations() {
    const { setApiCallInProgress } = useLoading();
    const [locations, setLocations] = useState(true);
    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(false);
    const [details, setDetails] = useState(false);
    const [csvContainer, setCsvContainer] = useState(true);
    const [allLocations, setAllLocations] = useState([]);
    const { setChosenLocationId, setChosenLocationName, setInputsTable, setLocationDetails } = useContext(AppContext);
    const [toggled, setToggled] = useState(false);

    const toggleComponent = () => {
        setLocations(false);
        setAdd(true);
        setDetails(false);
        setCsvContainer(false);
        setToggled(true);
    };

    const fetchData = async (showLoading = true) => {
        if (showLoading) setApiCallInProgress(true);
        try {
            const response = await makeRequest(URL_ROUTES.GET_LOCATIONS, "GET", {}, null, false);
            if (response.status !== 401) {
                setAllLocations(response.locations);
            } else {
                console.log(response.message);
            }
            if (showLoading) setApiCallInProgress(false);
        } catch (error) {
            if (showLoading) setApiCallInProgress(false);
            console.error("Error fetching locations:", error);
        }
    };

    //when components are toggled and the user is redirected to the locations component, the locations data is fetched again; wouldnt really make it aesthetic to show the loading spinner
    useEffect(() => {
        if (locations) fetchData(!toggled)
    }, [locations]);

    /** 
     * Sets the chosen location id and name, fetches the inputs data for the chosen location and displays the details component with the inputs table
     * @param {string} id - the id of the chosen location
     * @returns {void}
     */
    const chosenLocation = (id) => {
        setChosenLocationId(id);
        fetchInputsData(id);
        const chosenLocation = allLocations.find(location => location.id === id);
        chosenLocation ? setChosenLocationName(chosenLocation.name) : setChosenLocationName("Location not found");
        setDetails(true);
    };

    /**
     * Fetches the inputs data for the chosen location
     * @param {*} id 
     * @returns {void}
     */
    const fetchInputsData = async (id) => {
        setApiCallInProgress(true);
        try {
            const response = await makeRequest(`${URL_ROUTES.GET_LOCATION_BY_ID}/${id}`, "GET", {}, null, false);
            setLocationDetails(response.location);
            setInputsTable(response.inputs);
            setApiCallInProgress(false);
        } catch (error) {
            setApiCallInProgress(false);
            console.error("Error fetching inputs data:", error);
        }
    };

    return (
        <Fragment>
            <div className="components-container">
                {csvContainer && (
                    <div className="csv-container">
                        <select
                            className="location-selector"
                            defaultValue={"default"}
                            onChange={(e) => chosenLocation(e.target.value)}
                        >
                            <option value="default" disabled>
                                Choose location
                            </option>
                            {
                                allLocations !== undefined && allLocations.length > 0
                                    ? allLocations.map((location) => {
                                        return (
                                            <option key={location.id} value={location.id}>
                                                {location.name}
                                            </option>
                                        );
                                    })
                                    : "Locations not found"
                            }
                        </select>
                        <div>
                            <button
                                className="portal-btn-addlocation"
                                onClick={() => toggleComponent()}
                            >
                                New location
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {add && (
                <AddLocation
                    setLocations={setLocations}
                    setAdd={setAdd}
                    setDetails={setDetails}
                    setCsvContainer={setCsvContainer}
                    setToggled={setToggled}
                />
            )}

            {edit && (
                <EditLocation
                    setLocations={setLocations}
                    setAdd={setAdd}
                    setDetails={setDetails}
                    setEdit={setEdit}
                    setCsvContainer={setCsvContainer}
                />
            )}

            {details && (
                <LocationDetails
                    setDetails={setDetails}
                    setEdit={setEdit}
                    setLocations={setLocations}
                    setAdd={setAdd}
                    setCsvContainer={setCsvContainer}
                />
            )}
        </Fragment>
    );
}