const URL_ROUTES = {
    // user apis
    LOGIN: "user/login",
    RESET_PASSWORD: "resetPassword",
    LOGOUT: "user/logout",
    LIST_USERS: "users",
    UPDATE_USER: "user",
    DELETE_USER: "user",
    TOGGLE_USER_STATUS: "user/toggleStatus",
    // location apis
    GET_LOCATIONS: "location",
    GET_LOCATION_BY_ID: "location", // url is: location/:id
    ADD_LOCATION: "location/add",
    EDIT_LOCATION: "location",
    TOGGLE_LOCATION_STATUS: "location/toggleStatus",
    DELETE_LOCATION: "location",
    UPLOAD_IMAGE: "location/:name/image",
    UPDATE_IMAGE: "location/:name/:locationId/update-image",
    // pass apis
    GET_PASS: "pass",
    GET_PASS_USER_DATA: "pass/user",
    FETCH_PASS: "pass/fetch",
    // residents apis
    GET_RESIDENTS_DATA: "residents",
    GET_RESIDENTS_BY_NUMBER: "residents",
    // collection apis
    GET_COLLECTION: "collection",
    SAVE_TO_COLLECTION: "collection/save-data",
    DELETE_FROM_COLLECTION: "collection/delete",
    GET_COLLECTION_BY_ID: "collection",
    // submit apis
    SUBMIT_DATA: "submit",
    UPLOAD_DATA: "submit/upload",
    GENERATE_QR: "location/generateQR",
}

export const generateUrl = (route, params = {}) => {
    let url = URL_ROUTES[route];
    // Replace placeholders in the URL with actual values from the params object
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const value = encodeURIComponent(params[key]);
            url = url.replace(`:${key}`, value);
        }
    }
    return url;
}

export default URL_ROUTES;