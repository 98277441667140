import "../styles/components/notfound.scss";

export default function NotFound() {
    return (
        <div className="main-container">
            <div className="message-container notfound-main">
                <div className="warning-message warning-thankyou-message" >URL DOES NOT
                    EXIST.
                </div>
            </div>
        </div>
    );
}