import {Navigate, Outlet} from "react-router-dom";
import {AuthService} from "../helpers/Auth";

/**
 * This component is used to protect routes from being accessed by unauthenticated users.
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProtectedRoutes() {
    return AuthService.isAuthenticated() ? <Outlet/> : <Navigate to="/"/>;
}