import jwtDecode from 'jwt-decode';

// auth.js
const TOKEN_KEY = 'token';

/**
 * This is a helper function that allows us to set, get, and remove the token from localStorage.
 * @type {{setToken: AuthService.setToken,
 * removeToken: AuthService.removeToken,
 * getToken: (function(): string),
 * isAuthenticated: (function(): boolean)}}
*/
export const AuthService = {
    /**
     * This function sets the token in localStorage.
     * @param token
     */
    setToken: (token) => {
        localStorage.setItem(TOKEN_KEY, token);
    },
    /**
     * This function gets the token from localStorage.
     * @returns {string}
     */
    getToken: () => {
        return AuthService.isAuthenticated(true) ? localStorage.getItem(TOKEN_KEY) : null;
    },
    /**
     * This function removes the token from localStorage.
     */
    removeToken: () => {
        localStorage.removeItem(TOKEN_KEY);
    },
    /**
     * This function checks if the token is expired.
     * @param redirect
     * @returns {boolean}
     */
    isAuthenticated: (redirect = false) => {
        const token = localStorage.getItem(TOKEN_KEY);
        //check if expired, remove if it is
        if (token) {
            const decoded = jwtDecode(token);
            if (decoded.exp < Date.now() / 1000) {
                AuthService.removeToken();
                //if redirect is true, redirect to login page
                if (redirect) {
                    window.location.href = '/login';
                }

                return false;
            } else {
                return true;
            }
        }
        return false;
    },
    /**
     * This function sets the user id in the currentUserRole context.
     * @param userId
     * @returns {string}
     */
    getUserDetails: () => {
        return jwtDecode(AuthService.getToken());
    },
};
