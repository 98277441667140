import React, {Fragment, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import '../styles/components/reject.scss';
import {getMessage} from "../helpers/helpers";

export default function Reject() {
    const {location} = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState([]);

    useEffect(() => {

        setMessage(getMessage('thankYouMessage'));

        setTimeout(() => {
            navigate(`/${location}/pass`)
        }, 6000);

    }, []);

    return (
        <Fragment>
            <div className="thank-you-container">
                <div className='title'>
                    <h1>{message ?? 'Thank You For Visiting Us'}</h1>
                </div>
            </div>
        </Fragment>
    )
}
