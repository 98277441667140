import {getMessage} from "../helpers/helpers";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "../styles/components/thankyou.scss";

export default function ThankYou() {
    //on page load, show this page for 5 seconds, then redirect to pass page
    const [seconds, setSeconds] = useState(5);
    const navigate = useNavigate();
    const {location} = useParams();


    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        }
    }, [seconds]);
    if (seconds === 0) {
        navigate(`/${location}/pass`);
    }

    return (
        <div className="thank-you-container">
            <div className='title'>
                <h1>{getMessage('thankYouMessage') ?? 'Thank You For Visiting Us'}</h1>
            </div>
            <span>www.orangesafepass.com</span>
        </div>
    );
}