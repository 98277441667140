import React, { useState, useContext, Fragment } from "react";
import { AppContext } from "../../helpers/AppContext";
import loadingGif from "../../assets/Loading.png";
import "../../styles/components/form.scss";
import makeRequest from "../../helpers/DataRequester";
import URL_ROUTES from "../../constants";

export default function EditLocation(props) {
    const { LocationDetails, chosenLocationId, inputsTable } = useContext(AppContext);

    const [formData, setFormData] = useState({
        admin: LocationDetails.admin,
        address: LocationDetails.address,
        name: LocationDetails.name,
    });

    const [inputs, setInputs] = useState({
        inputName: inputsTable.name,
        unit: inputsTable.unit,
        email: inputsTable.email,
        mobile: inputsTable.mobile,
        resident: inputsTable.resident,
        companyName: inputsTable.companyName,
        purposeOfVisit: inputsTable.purposeOfVisit,
        visitDuration: inputsTable.visitDuration,
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputToggle = (name) => {
        setInputs({
            ...inputs,
            [name]: !inputs[name],
        });
    };

    const handleEdit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setLoading(true);

        try {
            const response = await makeRequest(URL_ROUTES.EDIT_LOCATION, "PUT", {}, {
                chosenLocationId,
                url: formData.name.split(" ").join("").toLowerCase(),
                backUpUrl: formData.name.split(" ").join("").toLowerCase(),
                ...formData,
                ...inputs,
            }, false);

            if (response.status === 200) {
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } else {
                console.log(response.message);
            }
        } catch (error) {
            console.error("Error editing location:", error);
        }

        props.setLocations(true);
        props.setAdd(false);
        props.setDetails(false);
        props.setEdit(false);

        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    function renderInput(name, placeholder, value, onChange) {
        return (
            <div className="input-wrapper">
                <input
                    placeholder={placeholder}
                    value={value}
                    type="text"
                    id={name}
                    name={name}
                    autoComplete="off"
                    onChange={onChange}
                    required
                />
            </div>
        );
    }

    function renderRadio(name, displayName) {
        return (
            <div>
                <input
                    id={`${name}Toggle`}
                    name={name}
                    type="radio"
                    onClick={()=>handleInputToggle(name)}
                    checked={inputs[name]}
                />
                <label htmlFor={`${name}Toggle`}>{displayName}</label>
            </div>
        );
    }

    return (
        <Fragment>
            <div className="add-container">
                <form className="form" name="add" onSubmit={handleEdit}>
                    <h2>Edit location</h2>
                    {renderInput("admin", "Manager full name", formData.admin, handleInputChange)}
                    {renderInput("address", "Location address", formData.address, handleInputChange)}
                    {renderInput("name", "Location name", formData.name, handleInputChange)}

                    <div className="edit-location-inputs-details-container">
                        <p>Choose which fields to display:</p>
                        {renderRadio("inputName", "Name")}
                        {renderRadio("unit", "Unit No")}
                        {renderRadio("email", "Email")}
                        {renderRadio("mobile", "Mobile")}
                        {renderRadio("resident", "Resident")}
                        {renderRadio("companyName", "Company name")}
                        {renderRadio("purposeOfVisit", "Purpose of visit")}
                        {renderRadio("visitDuration", "Visit duration")}

                    </div>

                    {loading && <img className="loading" src={loadingGif} alt="Loading is here" />}
                    <p className="error-message">{errorMessage}</p>
                    <div className="editlocation-btn-container">
                        <button type="submit">Update</button>
                        <button>Print</button>
                    </div>
                </form>
            </div>
        </Fragment>
    );
}
