import React, { useContext, useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Bar from "./Bar";
import { AppContext } from "../helpers/AppContext";
import { RiErrorWarningLine } from "react-icons/ri";
import "../styles/components/rules.scss";

export default function Rules() {
    const navigate = useNavigate();
    const { location } = useParams();
    const [rules, setRules] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [answerIsYes, setAnswerIsYes] = useState(true);
    const [warning, setWarning] = useState([]);
    const { setProgressBar } = useContext(AppContext);

    // Effect to set progress bar and load rules
    useEffect(() => {
        setProgressBar(35);
        setRules(JSON.parse(sessionStorage.getItem("rules")));
        setWarning(JSON.parse(sessionStorage.getItem("warning"))[0]);
    }, [setProgressBar]);

    const residentAnswer = (answer) => {
        if (answer === "yes") {
            sessionStorage.setItem("answer", "Accepted");
        } else {
            sessionStorage.setItem("answer", "None apply");
        }
    };

    const navigatePage = () => {
        setAnswerIsYes(true);
        residentAnswer("yes");
        const storedQuestions = JSON.parse(sessionStorage.getItem("questions"));
        if (storedQuestions?.length > 0 && storedQuestions[0].content !== "") {
            navigate(`/${location}/questions`);
        } else {
            sessionStorage.setItem("answer", "Not required");
            navigate(`/${location}/submit`);
        }
    };

    const openModal = () => {
        setShowModal(true);
    }

    // Handle navigation back
    function backHandle() {
        navigate(-1);
    }

    const rejectRules = () => {
        sessionStorage.setItem("answer", "Not accepted");
        setAnswerIsYes(false);
    }

    return (
        <Fragment>
            <Bar />
            <div className="main-container">
                <div className="back-button-container">
                    <div className="back-button" onClick={backHandle}>
                        Back
                    </div>
                </div>
                {answerIsYes ? (
                    <div className="rules-questions-container">
                        <h2>Rules to comply</h2>
                        {rules.map((rule, index) => (
                            <div key={index} className="rules-questions">
                                <h1>R{index + 1}</h1>
                                <p>{rule.content}</p>
                            </div>
                        ))}
                        <button onClick={navigatePage}>Accept all</button>
                        <button style={{ border: "none", backgroundColor: "transparent", color: "grey", fontSize: "15px", textDecoration: "none", height: "0px", boxShadow: "none", marginTop: "10px" }} onClick={openModal}>Do Not Accept</button>

                        {showModal && (
                            <div className="overlay">
                                <div className="modal">
                                    <div className="modal-content">
                                        <RiErrorWarningLine style={{ fontSize: "5rem", color: "#ff0000" }} />
                                        <h3>Are you sure?</h3>
                                        <p>If you do not accept the rules, you are not permitted to use the facility.</p>
                                        <div className="modal-buttons">
                                            <button className="modal-reject" onClick={rejectRules}>Reject Rules</button>
                                            <button className="modal-accept" onClick={navigatePage}>Accept Rules</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                ) : (
                    <div style={{ marginTop: "50px" }} className="message-container">
                        <h2 className="warning-message">{warning.content}</h2>
                        <button
                            onClick={() => {
                                navigate(`/${location}/userWaiver`);
                            }}
                        >
                            Continue
                        </button>
                    </div>
                )}
                <a
                    className="website-link"
                    href="https://www.orangesafepass.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    www.orangesafepass.com
                </a>
            </div>
        </Fragment>
    );
}
